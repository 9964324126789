import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { useRecoilState } from 'recoil'
import { MdLanguage } from 'react-icons/md'

import { Flex, Button } from '@chakra-ui/react'

import {
  Footer,
  FooterBody,
  FooterColumn,
  FooterDivider,
  FooterBottom,
  Main,
  P,
  BookingModal,
  WhatsappButton,
  Popup,
} from '../ui'

import SocialLinks from './socialLinks'
import Header from './header'
import FloatingBook from './floatingBook'

import { bookingModalState } from '../store'
import SEO from './seo'
import ButtonBook from './buttonBook'

import logoFooter from '../images/logo.png'
import favico from '../images/logo.png'

import FuturaPTBoldWoff from '../fonts/FuturaPTBold.woff'
import FuturaPTBoldWoff2 from '../fonts/FuturaPTBold.woff2'

import FuturaPTExtraMediumWoff from '../fonts/FuturaPTMedium.woff'
import FuturaPTExtraMediumWoff2 from '../fonts/FuturaPTMedium.woff2'

const globalStyles = css`
  @font-face {
    font-family: Futura;
    src: url(${FuturaPTBoldWoff2}) format('woff2'), url(${FuturaPTBoldWoff}) format('woff');
    font-weight: normal;
  }

  @font-face {
    font-family: Futura;
    src: url(${FuturaPTExtraMediumWoff2}) format('woff2'),
      url(${FuturaPTExtraMediumWoff}) format('woff');
    font-weight: black;
  }

  * {
    font-size: 14px;
    font-family: Futura, Arial, Helvetica, sans-serif !important;
    font-weight: normal;

    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }

  p,
  span {
    font-size: 16px;
    max-height: 999999px;
    font-weight: 300 !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
`

function Layout({
  children,
  title,
  variantHeader,
  disableHeader,
  disableWhatsapp,
  disablePopup,
  disableFloatingBanner,
}) {
  const isCoyote = process.env.GATSBY_HOTEL_VAR === 'casa-coyote'

  const { t } = useTranslation()
  const { language, changeLanguage } = useI18next()
  const [bookingModal, setBookingModal] = useRecoilState(bookingModalState)

  return (
    <>
      <Global styles={globalStyles} />
      <SEO title={`${title}`} />

      {!disableHeader && (
        <Header
          variantHeader={variantHeader}
          backgroundColor={{
            base: 'black',
            md: variantHeader === 'solid' ? 'black' : 'rgba(0,0,0,0.45)',
          }}
        />
      )}
      <Flex direction="column" minHeight="100vh">
        <Main>{children}</Main>
      </Flex>
      <Footer backgroundColor="gray.200" paddingTop={20}>
        <FooterBody>
          <FooterColumn align="center">
            <Link to="/">
              <Flex width={{ base: '150px', md: '200px' }}>
                <img src={favico} />
              </Flex>
            </Link>
          </FooterColumn>
        </FooterBody>
        <FooterBottom
          direction={{ base: 'column', md: 'row' }}
          justify="space-between"
          marginTop={4}
        >
          <P fontSize="sm" lineHeight="shorter" textAlign={{ base: 'center', lg: 'left' }}>
            © Derechos reservados, 2023. EMBLEMA - SPORTS BAR
          </P>
          <Flex>
            <Button
              colorScheme="black"
              variant="outline"
              leftIcon={<MdLanguage />}
              onClick={() => changeLanguage(language === 'es' ? 'en' : 'es')}
              _hover={{ background: 'transparent' }}
            >
              {language === 'es' ? 'Change to English' : 'Cambiar a Español'}
            </Button>
          </Flex>
        </FooterBottom>
      </Footer>

      {/* {!disableWhatsapp && (
        <WhatsappButton
          bottom="75px"
          href={`https://api.whatsapp.com/send?phone=5219983497125&text=${t(
            'common:whatsappText'
          )}`}
        />
      )} */}

      {/* {!disableFloatingBanner && (
        <FloatingBook
          title="Hacienda Poxilá"
          desc="La mejor tarifa web, garantizado"
          colorScheme="red"
        />
      )} */}

      {/* {!disablePopup && (
        <Popup>
          <ButtonBook
            width="100%"
            height="100%"
            promotion="lastminute"
            arrival="2021-02-15"
            departure="2021-02-16"
          >
            <img
              style={{ width: '100%' }}
              src={
                language === 'es'
                  ? isCoyote
                    ? popupEsCoyote
                    : popupEsXaman
                  : isCoyote
                  ? popupEnCoyote
                  : popupEnXaman
              }
            />
          </ButtonBook>
        </Popup>
      )} */}

      <BookingModal
        isOpen={bookingModal.isOpen}
        onClose={() => setBookingModal((obj) => ({ ...obj, isOpen: false }))}
        url={`https://rbe.zaviaerp.com/${bookingModal.promotion}?hotel=casacoyote&arrival=${
          bookingModal.arrival
        }&departure=${bookingModal.departure}&lng=${language === 'es' ? 'es' : 'en'}&currency=${
          language === 'es' ? 'mxn' : 'usd'
        }`}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  disableHeader: PropTypes.bool,
  disableWhatsapp: PropTypes.bool,
  disablePopup: PropTypes.bool,
  disableFloatingBanner: PropTypes.bool,
  title: PropTypes.string,
  variantHeader: PropTypes.oneOf(['solid', 'transparent']),
}

Layout.defaultProps = {
  disableHeader: false,
  disableWhatsapp: false,
  disablePopup: false,
  disableFloatingBanner: false,
  title: '',
  variantHeader: 'transparent',
}

export const query = graphql`
  fragment fixedImage on File {
    childImageSharp {
      fixed(width: 680, quality: 65) {
        ...GatsbyImageSharpFixed
      }
    }
  }

  fragment fluidImage on File {
    childImageSharp {
      fluid(quality: 65) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment bannerSectionImage on File {
    childImageSharp {
      fluid(maxWidth: 1270, quality: 65) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment multipleFixedImage on FileConnection {
    edges {
      node {
        childImageSharp {
          fixed(width: 680, quality: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

export default Layout
