import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Stack, useTheme } from '@chakra-ui/react'

import { H1, H2 } from '../globals'
import { FeaturedBanner } from '..'
import Carousel from '../carousel'
import { v4 } from 'uuid'

export function HeroBody({ children, ...restProps }) {
  const theme = useTheme()

  return (
    <Stack
      justify="center"
      spacing={4}
      width="100%"
      maxWidth={{ lg: theme.breakpoints.mw }}
      paddingRight={{ base: 6, md: 10, xl: 20 }}
      paddingLeft={{ base: 6, md: 10, xl: 20 }}
      pointerEvents="none"
      zIndex={1}
      {...restProps}
    >
      {children}
    </Stack>
  )
}

HeroBody.propTypes = {
  children: PropTypes.node,
}

HeroBody.defaultProps = {
  children: null,
}

export function HeroTitle({ children, ...restProps }) {
  return (
    <H1 color="white" textShadow="0px 0px 5px black" {...restProps}>
      {children}
    </H1>
  )
}

HeroTitle.propTypes = {
  children: PropTypes.node,
}

HeroTitle.defaultProps = {
  children: null,
}

export function HeroSubtitle({ children, ...restProps }) {
  return (
    <H2
      color="white"
      textShadow="0px 0px 5px black"
      fontWeight="light"
      marginTop={0}
      {...restProps}
    >
      {children}
    </H2>
  )
}

HeroSubtitle.propTypes = {
  children: PropTypes.node,
}

HeroSubtitle.defaultProps = {
  children: null,
}

export function HeroCta({ children, ...restProps }) {
  return (
    <Stack pointerEvents="all" marginTop={5} width={{ sm: 'fit-content' }} {...restProps}>
      {children}
    </Stack>
  )
}

HeroCta.propTypes = {
  children: PropTypes.node,
}

HeroCta.defaultProps = {
  children: null,
}

export function Hero({ children, minHeight, image, gallery, objectPosition, ...restProps }) {
  return (
    <Flex
      backgroundColor="gray.900"
      width="100%"
      overflow="hidden"
      position="relative"
      justifyContent="center"
      alignItems="center"
      minHeight={minHeight}
      {...restProps}
    >
      {children}
      {image && (
        <Box left="0" top="0" width="100%" height="100%" position="absolute" zIndex={0}>
          <FeaturedBanner opacity={1} height={minHeight} position="initial" image={image} />
        </Box>
      )}

      {gallery && (
        <Box left="0" top="0" width="100%" height="100%" position="absolute" zIndex={0}>
          <Carousel autoplay>
            {gallery.map((image) => (
              <FeaturedBanner key={v4()} height={minHeight} position="initial" image={image} />
            ))}
          </Carousel>
        </Box>
      )}
    </Flex>
  )
}

Hero.propTypes = {
  minHeight: PropTypes.any,
  image: PropTypes.node,
  children: PropTypes.node,
  gallery: PropTypes.array,
}

Hero.defaultProps = {
  minHeight: { base: '400px', md: '500px', lg: '600px' },
  image: null,
  children: null,
  gallery: null,
}
