import React from 'react'
import styled from '@emotion/styled'
import { FaMapMarkedAlt } from 'react-icons/fa'
import { Stack, IconButton } from '@chakra-ui/react'

import { P } from '../ui'

const A = styled.a`
  font-size: inherit;
  text-decoration: underline;
`

const SocialLink = ({ icon, to }) => (
  <IconButton as="a" href={to} target="_blank" variant="outline" colorScheme="white" icon={icon} />
)

function SocialLinks(props) {
  return (
    <Stack spacing={2} {...props}>
      <P></P>
    </Stack>
  )
}

export default SocialLinks
