import React from 'react'
import { useTranslation, Link, useI18next } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { FaInstagram } from 'react-icons/fa'
import { MdLanguage } from 'react-icons/md'

import { Box, Button, Flex, Stack } from '@chakra-ui/react'

import { Menu, MenuLink } from '../ui'

import ButtonBook from './buttonBook'

import logo from '../images/logo-white.png'

function Header({ variantHeader, ...restProps }) {
  const { t } = useTranslation()
  const { language, changeLanguage } = useI18next()

  return (
    <Menu
      logo={
        <Link to="/">
          <Flex height={{ base: '27px', md: '50px' }} justifyContent={{ lg: 'center' }}>
            <img style={{ height: '100%', width: 'auto' }} src={logo} />
          </Flex>
        </Link>
      }
      zIndex={2}
      rightButton={
        <Stack isInline align="center">
          <ButtonBook to={t('common:nav.reservations.to')}>
            {t('common:nav.reservations.text')}
          </ButtonBook>
          <Box as="a" href="https://instagram.com" target="_blank">
            <FaInstagram size="1.35rem" color="white" />
          </Box>
          <Button
            color="#ffffff"
            variant="outline"
            leftIcon={<MdLanguage />}
            onClick={() => changeLanguage(language === 'es' ? 'en' : 'es')}
            _hover={{ background: 'transparent' }}
          >
            {language === 'es' ? 'Change to English' : 'Cambiar a Español'}
          </Button>
        </Stack>
      }
      position={{
        md: variantHeader === 'solid' ? 'initial' : 'absolute',
      }}
      {...restProps}
    >
      <MenuLink color="white" as={Link} to={t('common:nav.about.to')}>
        {t('common:nav.about.text')}
      </MenuLink>
      <MenuLink color="white" as={Link} to={t('common:nav.food.to')}>
        Menú
      </MenuLink>
      <MenuLink color="white" as={Link} to={t('common:nav.gallery.to')}>
        {t('common:nav.gallery.text')}
      </MenuLink>
      <MenuLink color="white" as={Link} to={t('common:nav.contact.to')}>
        {t('common:nav.contact.text')}
      </MenuLink>
    </Menu>
  )
}

Header.propTypes = {
  variantHeader: PropTypes.oneOf(['solid', 'transparent']),
}

Header.defaultProps = {
  variantHeader: 'solid',
}

export default Header
